const data = [
    {
        title: 'Mazu',
        subtitle: 'Illustration | Digital + Riso Print',
        image: '/project_title_cards/illustrations/mazu_original.jpg',
        date: '2024-01-06',
        desc:
          'Mazu is the Fujianese goddess of the sea, who safeguards sailors from drowning. Her temples are'
          + ' all over Fujian and other places in south China / SE Asia. I visited '
          + ' one in Hong Kong and saw what I thought to be a sword made of water (it was not)' 
          + ' which inspired this painting.',
    },

    {
        title: 'Rose Medallion Qingming Jie',
        subtitle: 'Comic | Risograph printed',
        image: '/project_title_cards/comics/qingmingjie_plate.png',
        date: '2024-11-20',
        desc:
          'This plate shaped comic commemorates celebrating Qingming Jie with '
          + 'my family as a child. The comic takes place in the panels of a Rose'
          + ' Medallion plate, a traditional Chinese export ware that features the colorful'
          + ' flora and fauna of tropical south China, where these plates were historically manufactured,'
          + ' coupled with western art'
          + ' motifs like the window panels showing narrative scenes.',
    },
    {
        title: 'Millennia Museum',
        subtitle: 'Comic | Risograph printed',
        image: '/project_title_cards/comics/millennia_museum_cover.png',
        date: '2025-03-01',
        desc:
          'Two ancient enemies fall through time into a strange museum'
          + ' where the new exhibit features their own ancient past'
          + ' and unknown future. A silent short story.',
    },
    {
        title: 'The wise love the sea; the virtuous love the mountains',
        subtitle: 'Illustration | Digital + Risograph printed',
        image: '/project_title_cards/illustrations/confucius_folding.png',
        date: '2025-3-20',
        desc:
          "Depicted on the back of these six screen-folding bookmarks is a poem from Confucius's Analects: 'The wise love the sea; the virtuous love the mountains // The wise are restless; the virtuous are rooted // The wise are happy; the virtuous long lived. '" 
    },

    {
        title: 'Memory Waves',
        subtitle: 'Comic | Digital',
        image: '/project_title_cards/comics/memory_waves.png',
        date: '2023-7-20',
        desc:
          'An immortal wanders around modern hong kong and remembers another time when an empire invaded the south. '
          + ' Inspired by the interesting choice to establish an Imperial Palace museum in Hong Kong.',
    },
    {
        title: 'Flotsam, Jetsam',
        subtitle: 'Comic | Art Students League 2022 Anthology',
        image: '/project_title_cards/comics/flotsam_3.jpg',
        date: '2022-9-20',
        desc:
          'A short story about a trip to the underworld to save a lovely, and rather scaly, princess. The theme of the anthology: exploration!',
    },
    {
        title: 'Porcelain Doll',
        subtitle: 'Illustration | Digital + Risograph printed',
        image: '/project_title_cards/illustrations/porcelain_doll_back.png',
        date: '2025-2-20',
        desc:
          'Concept cover art for a future comic about a porcelain doll. Features the traditional'
          + ' qinghua color palette of cobalt blue on white glaze, the most iconic chinese porcelain style.',
    },
    {
        title: 'Poeny Dream',
        subtitle: 'Illustration | Digital',
        image: '/project_title_cards/illustrations/mudan_boy.png',
        date: '2022-8-20',
        desc:
          'Peony study with an old OC',
    }

];

export default data;
